import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

const StateItem = ({ state, currentState, onClick }) => {
  const active = useRef(currentState);
  //   useEffect(() => {
  //     active.current.scrollIntoView({
  //       block: "center",
  //       inline: "start"
  //     });
  //   }, [currentState]);

  return (
    <div
      ref={active}
      onClick={() => {
        onClick(state);
      }}
      className={
        currentState.stateName === state.stateName ? "active" : "not-active"
      }
    >
      <Link
        to={`/united-states/${state.stateName
          .toLowerCase()
          .replace(/ /g, "-")}/deals`}
      >
        {state.stateName}
      </Link>
    </div>
  );
};

export default StateItem;
