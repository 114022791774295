import React, { Fragment } from "react";
import Page from "../Components/Page";

const Pagination = ({
  pagination,
  pagination: { pages, totalPages, currentPage },
  setPagination
}) => {
  return (
    <div className="pagination d-flex py-3">
      <div className="pagination-wrapper d-flex mx-auto">
        {/* if pages are less than and equal to 4 */}
        {pages[0] && totalPages < 5 && totalPages !== 1 && (
          <Fragment>
            {currentPage !== 1 && (
              <div
                className="page-navigator d-flex"
                onClick={() => {
                  setPagination({
                    ...pagination,
                    currentPage: currentPage - 1
                  });
                }}
              >
                <i className="fas fa-chevron-left m-auto"></i>
              </div>
            )}
            {pages.map((page, index) => {
              return (
                <Page
                  setPagination={setPagination}
                  pagination={pagination}
                  key={index}
                  page={index + 1}
                  index={index}
                  currentPage={currentPage}
                />
              );
            })}
            {currentPage !== totalPages && (
              <div
                className="page-navigator d-flex"
                onClick={() => {
                  setPagination({
                    ...pagination,
                    currentPage: currentPage + 1
                  });
                }}
              >
                <i className="fas fa-chevron-right m-auto"></i>
              </div>
            )}
          </Fragment>
        )}
        {/* if pages are more than and equal to 5 */}
        {totalPages > 4 && (
          <Fragment>
            {currentPage > 1 && pages[0] && (
              <div
                className="page-navigator d-flex"
                onClick={() =>
                  setPagination({
                    ...pagination,
                    currentPage: currentPage - 1
                  })
                }
              >
                <i className="fas fa-chevron-left m-auto"></i>
              </div>
            )}
            {pages.map((page, index, pages) => {
              if (
                index === pages.length - 1 &&
                currentPage < pagination.totalPages - 3
              ) {
                return (
                  <Fragment key={"dots-right"}>
                    <div className="d-flex mx-2">
                      <h5 className="mx-auto dots">...</h5>
                    </div>
                    <Page
                      setPagination={setPagination}
                      pagination={pagination}
                      page={index + 1}
                      currentPage={currentPage}
                      index={index}
                      key={index + 1}
                    />
                  </Fragment>
                );
              }
              if (
                currentPage > 4 &&
                currentPage === index + 1 &&
                currentPage < totalPages - 3
              ) {
                return (
                  <Fragment key={"first-pages-2"}>
                    <Page
                      key={1}
                      page={1}
                      setPagination={setPagination}
                      pagination={pagination}
                    />
                    <div className="d-flex mx-2" key={"dots-left"}>
                      <h5 className="mx-auto dots">...</h5>
                    </div>
                    <div className="d-none d-sm-flex">
                      <Page
                        key={index}
                        page={index}
                        setPagination={setPagination}
                        pagination={pagination}
                      />
                    </div>
                    <Page
                      key={index + 1}
                      page={index + 1}
                      currentPage={currentPage}
                      index={index}
                      setPagination={setPagination}
                      pagination={pagination}
                    />
                    <div className="d-none d-sm-flex">
                      <Page
                        key={index + 2}
                        page={index + 2}
                        setPagination={setPagination}
                        pagination={pagination}
                      />
                    </div>
                  </Fragment>
                );
              }
              if (currentPage > 2 && currentPage < 5 && index === currentPage) {
                return (
                  <Fragment key={"first-pages"}>
                    {currentPage !== 3 && (
                      <Page
                        setPagination={setPagination}
                        pagination={pagination}
                        key={index}
                        page={index}
                        currentPage={currentPage}
                        index={index - 1}
                      />
                    )}
                    <div className="d-none d-sm-flex">
                      <Page
                        key={index + 1}
                        page={index + 1}
                        setPagination={setPagination}
                        pagination={pagination}
                      />
                    </div>
                  </Fragment>
                );
              }
              if (index < 3 && currentPage < 5) {
                return (
                  <Fragment key={index + 1}>
                    <div className="d-none d-sm-flex">
                      <Page
                        key={index + 1}
                        page={index + 1}
                        currentPage={currentPage}
                        index={index}
                        setPagination={setPagination}
                        pagination={pagination}
                      />
                    </div>
                    {/* for mobile only */}
                    {currentPage !== 1 && index === 0 && (
                      <Fragment>
                        <div className="d-flex d-sm-none">
                          <Page
                            key={1}
                            page={1}
                            setPagination={setPagination}
                            pagination={pagination}
                          />
                        </div>
                        <div className="d-flex d-sm-none mx-2">
                          <h5 className="mx-auto dots">...</h5>
                        </div>
                      </Fragment>
                    )}
                    {index + 1 === currentPage && (
                      <Fragment>
                        <div className="d-flex d-sm-none">
                          <Page
                            key={index + 1}
                            page={index + 1}
                            currentPage={currentPage}
                            index={index}
                            setPagination={setPagination}
                            pagination={pagination}
                          />
                          {currentPage === 1 && (
                            <Page
                              key={index + 2}
                              page={index + 2}
                              setPagination={setPagination}
                              pagination={pagination}
                            />
                          )}
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                );
              }

              // last 4 pages
              if (
                totalPages === 6 &&
                index + 1 === currentPage &&
                currentPage === 4
              ) {
                return null;
              }
              if (currentPage > totalPages - 4 && index > totalPages - 4) {
                return (
                  <Fragment>
                    <div className="d-none d-sm-flex">
                      <Page
                        key={index + 1}
                        page={index + 1}
                        currentPage={currentPage}
                        index={index}
                        setPagination={setPagination}
                        pagination={pagination}
                      />
                    </div>

                    {index + 1 === currentPage && (
                      <Fragment>
                        <div className="d-flex d-sm-none">
                          {currentPage === totalPages && (
                            <Page
                              key={index}
                              page={index}
                              setPagination={setPagination}
                              pagination={pagination}
                            />
                          )}
                          <Page
                            key={index + 1}
                            page={index + 1}
                            currentPage={currentPage}
                            index={index}
                            setPagination={setPagination}
                            pagination={pagination}
                          />
                        </div>
                      </Fragment>
                    )}
                    {currentPage !== totalPages && index === totalPages - 1 && (
                      <Fragment>
                        <div className="d-flex d-sm-none mx-2">
                          <h5 className="mx-auto dots">...</h5>
                        </div>
                        <div className="d-flex d-sm-none">
                          <Page
                            key={totalPages}
                            page={totalPages}
                            setPagination={setPagination}
                            pagination={pagination}
                          />
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                );
              }
              if (currentPage > totalPages - 4 && index === 0) {
                return (
                  <Fragment key={"dots-right"}>
                    <Page
                      key={1}
                      page={1}
                      setPagination={setPagination}
                      pagination={pagination}
                    />
                    <div className="d-flex mx-2">
                      <h5 className="mx-auto dots">...</h5>
                    </div>
                  </Fragment>
                );
              }
              if (
                currentPage > totalPages - 4 &&
                currentPage < totalPages - 1 &&
                index < totalPages - 4 &&
                index > totalPages - 6
              ) {
                return (
                  <Fragment key={"last-pages"}>
                    {currentPage === totalPages - 3 && (
                      <div className="d-none d-sm-flex">
                        <Page
                          key={index + 1}
                          page={index + 1}
                          currentPage={currentPage}
                          index={index}
                          setPagination={setPagination}
                          pagination={pagination}
                        />
                      </div>
                    )}
                    <div className="d-none d-sm-flex">
                      <Page
                        key={index + 2}
                        page={index + 2}
                        currentPage={currentPage}
                        index={index + 1}
                        setPagination={setPagination}
                        pagination={pagination}
                      />
                    </div>
                    {index + 2 === currentPage && (
                      <div className="d-flex d-sm-none">
                        <Page
                          key={index + 2}
                          page={index + 2}
                          currentPage={currentPage}
                          index={index + 1}
                          setPagination={setPagination}
                          pagination={pagination}
                        />
                      </div>
                    )}
                  </Fragment>
                );
              }
            })}
            {currentPage !== totalPages && pages[0] && (
              <div
                className="page-navigator d-flex"
                onClick={() =>
                  setPagination({
                    ...pagination,
                    currentPage: currentPage + 1
                  })
                }
              >
                <i className="fas fa-chevron-right m-auto"></i>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Pagination;
