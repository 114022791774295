import React from "react";

const Page = ({ currentPage, page, index, setPagination, pagination }) => {
  return (
    <div
      className={`d-flex mx-3 page ${currentPage === index + 1 && "active"}`}
      onClick={() => {
        setPagination({ ...pagination, currentPage: page });
      }}
    >
      <h5 className="m-auto">{page}</h5>
    </div>
  );
};

export default Page;
