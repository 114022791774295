import React from "react";
import HttpsRedirect from "react-https-redirect";
import Home from "./Pages/Home";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Helmet>
        <title>{`JanesDeal: Search Marijuana Deals`}</title>
        <meta
          name="description"
          content={`Search the world's marijuana deals, including keywords, products, brands and dispensaries. JanesDeal has many special features to help you find exactly what you're looking for.`}
        />
      </Helmet>
      <HttpsRedirect>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/united-states/:state/deals" component={Home} />
          <Route
            exact
            path="/united-states/:state/deals/search"
            component={Home}
          />
          <Route render={props => <Home {...props} status={404} />} />
        </Switch>
      </HttpsRedirect>
    </Router>
  );
}

export default App;
