import React, { useState, useEffect } from "react";
import DealItem from "./DealItem";
import moment from "moment";
import _ from "lodash";

const ShopItem = ({ item, setCurrent }) => {
  const [isExpaned, setIsExpaned] = useState(false);

  const changeCurrent = () => {
    setCurrent(item);
  };

  useEffect(() => {
    setIsExpaned(item.search ? true : false);
  }, [item]);
  const currentDay = moment()
    .format("dddd")
    .toLowerCase();
  //
  const currentTime = new Date().getHours();
  const currentWorkingHours = _.find(
    item.hours,
    o => o.dayOfWeek === currentDay
  );
  let openHour;
  let closeHour;
  if (currentWorkingHours) {
    openHour = currentWorkingHours.open.substring(11, 13);
    closeHour = currentWorkingHours.close.substring(11, 13);
  }

  let isOpen = false;
  if (currentTime >= openHour && currentTime < closeHour) {
    isOpen = true;
  }

  const sortedDeals = item.deals.sort((a, b) => {
    return b.intId - a.intId;
  });

  return (
    <li
      className="shopItem mb-2"
      onMouseEnter={changeCurrent}
      onMouseLeave={() => {
        setCurrent(null);
      }}
    >
      <div className="shop-header row" onClick={() => setIsExpaned(!isExpaned)}>
        <div className="text-left header-col1">
          <h2 className="mb-0 shop-name">{item.name}</h2>
          <div className="address">
            {item.address1 && `${item.address1},`} {item.city}
          </div>
          <div>
            {isOpen
              ? `OPEN - Closes at ${moment
                  .utc(closeHour * 3600 * 1000)
                  .format("h A")}`
              : `CLOSED - Opens at ${moment
                  .utc(openHour * 3600 * 1000)
                  .format("h A")}`}
          </div>
        </div>
        <div className="text-right header-col2">
          {item.deals.length !== 0 && (
            <div className="deals-count">{item.deals.length + " Deals"}</div>
          )}
          <div className="distance">
            {item.distance && `${item.distance} mi`}
          </div>
          <div onClick={changeCurrent}>View on map</div>
        </div>
      </div>
      <div>
        <div className="accordion">
          {sortedDeals.length === 0 && (
            <div className="card">
              <button className="btn btn-link">
                <p className="text-center w-100">See dispensary for deals</p>
              </button>
            </div>
          )}
          {sortedDeals.map((deal, key) => (
            <DealItem
              key={key}
              item={deal}
              search={item.search}
              isAllExpanded={isExpaned}
            />
          ))}
        </div>
      </div>
    </li>
  );
};

export default ShopItem;
