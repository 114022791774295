import React from "react";

const MyMarker = () => {
  return (
    <div className="my-marker-wrapper">
      <div className="my-marker"></div>
      <div className="my-marker-pulse"></div>
    </div>
  );
};

export default MyMarker;
