import React, { useState, useEffect, Fragment } from "react";
import Highlighter from "react-highlight-words";
import AnimateHeight from "react-animate-height";
import moment from "moment";

const DealItem = ({ item, search, isAllExpanded }) => {
  const [show, setShow] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(isAllExpanded ? "auto" : 0);
  }, [isAllExpanded]);
  return (
    <div className="card" onClick={() => setShow(!show)}>
      <div className="card-header">
        <button
          className="btn btn-link row"
          onClick={() => setHeight(height === 0 ? "auto" : 0)}
        >
          <p className="valid-date text-rigth col-12 p-0">
            {item.sDate === "" || item.eDate === "" ? (
              <Fragment>See dispensary for valid dates</Fragment>
            ) : null}
            {item.sDate !== "" && item.eDate !== "" && "Valid "}
            {item.sDate !== "" &&
              item.eDate !== "" &&
              moment(item.sDate).format("MM/DD/YYYY")}
            {item.sDate !== "" && item.eDate !== "" && " - "}
            {item.sDate !== "" &&
              item.eDate !== "" &&
              moment(item.eDate).format("MM/DD/YYYY")}
          </p>
          <p className="col-12 text-left p-0">
            <Highlighter
              style={{ textAlign: "left" }}
              highlightClassName="highlight"
              autoEscape={true}
              searchWords={[search]}
              textToHighlight={item.title}
            />
          </p>
        </button>
      </div>
      <AnimateHeight duration={200} height={height}>
        <div className="card-body">
          <p style={{ whiteSpace: "pre-line" }}>
            <Highlighter
              highlightClassName="highlight"
              autoEscape={true}
              searchWords={[search]}
              textToHighlight={item.detail}
            />
          </p>
        </div>
      </AnimateHeight>
    </div>
  );
};

export default DealItem;
