import React, { useEffect, useState } from "react";

const Marker = ({
  shop,
  current,
  viewShops,
  index,
  bringToFront,
  pages,
  currentPage
}) => {
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (current && current.name === shop.name) {
      return setStyle({
        currentSpanStyle: {
          color: "#3ace84",
          fontSize: "13px",
          top: "34%",
          left: "34%"
        },
        currentMarkerStyle: {
          borderColor: "#3ace84",
          backgroundColor: "#fff",
          borderWidth: "4px",
          width: "48px",
          height: "48px",
          zIndex: "1000",
          transform: "translate(-50%, -48px) rotate(-45deg)"
        }
      });
    }
    if (!current || (current && current.name !== shop.name)) {
      return setStyle({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <div
      className="marker"
      style={style.currentMarkerStyle}
      onClick={() => bringToFront(pages[currentPage - 1], index, 0, shop)}
    >
      <span style={style.currentSpanStyle}>
        <i className="fas fa-cannabis"></i> {shop.deals.length}
      </span>
    </div>
  );
};

export default Marker;
