import React, { Fragment, useState, useEffect, useRef } from "react";
import queryString from "query-string";

import "dragscroll/dragscroll";
import navlogo from "../img/janes-deal-logo-v3.png";
import navigateIcon from "../img/location-arrow-solid.svg";
import closeIcon from "../img/close-icon.png";
import searchIcon from "../img/search-icon.svg";
import searchIconGrey from "../img/search-icon-grey.svg";
import burgerIcon from "../img/bars-solid.svg";
import _ from "lodash";

import StateItem from "./StateItem";

const HeaderBar = ({
  getUserState,
  filterByKeyword,
  sortByLocation,
  viewShops,
  getDealsByState,
  states,
  currentState,
  setTheState,
  searchText,
  setSearchSummary,
  setText,
  currentLat,
  currentLong,
  setIsfiltered,
  pagination,
  setPagination,
  history,
  setStatus
}) => {
  // const onKeyWordChange = _.debounce(text => filterByKeyword(text), 500);
  const input = useRef(null);
  const form = useRef(null);

  const onChange = e => {
    setText(e.target.value);
  };

  // set url param to state
  const setUrlState = () => {
    if (currentState.stateName) {
      history.push({
        pathname: `/united-states/${currentState.stateName
          .toLowerCase()
          .replace(/ /g, "-")}/deals`
      });
    }
  };

  const clearText = () => {
    setText("");
    setSearchSummary(null);
    setIsfiltered(false);
    filterByKeyword("");
    setUrlState();
  };

  const changeState = state => {
    setPagination({ ...pagination, currentPage: 1 });
    setText("");
    setIsfiltered(false);
    setTheState(state);
    setSearchSummary(null);
  };

  const onSubmit = e => {
    if (currentState.stateName) {
      setPagination({ ...pagination, currentPage: 1 });
      e.preventDefault();
      history.push({
        pathname: `/united-states/${currentState.stateName
          .toLowerCase()
          .replace(/ /g, "-")}/deals/search`,
        search: `s=${searchText}`
      });
      filterByKeyword(searchText);
      setIsfiltered(true);
      input.current.blur();
    }
  };

  useEffect(() => {
    getDealsByState(currentState.stateAbbr);
    !searchText && setUrlState();
    setStatus(null);
  }, [currentState]);

  useEffect(() => {
    sortByLocation(viewShops);
  }, [currentLat, currentLong]);

  return (
    <Fragment>
      <div className="header-wrapper">
        <div className="container mobile-fluid">
          <nav className="row header-section">
            <div className="col-2 d-md-none d-flex mt-2">
              <img
                className="img-fluid nav-toggle my-auto mr-auto d-none"
                src={burgerIcon}
                alt="Toggle Sidebar"
              />
            </div>

            <div className="col-md-2 col-8 d-flex mt-2 order-md-2 pr-0">
              <img
                src={navlogo}
                alt="Jane's Deal"
                className="m-auto img-fluid nav-logo"
              />
            </div>

            <div className="col-md-2 col-2 d-flex mt-2 order-md-4">
              <button
                className="btn btn-primary ml-auto my-auto sign-in d-none"
                type="submit"
              >
                Sign In
              </button>
            </div>

            <div className="col-md-8 col-12 mt-2 order-md-3 d-flex">
              <form className="w-100" action="." onSubmit={onSubmit} ref={form}>
                <div className="input-group my-auto">
                  <span className="input-group-text pre-input" id="searchBox">
                    <div className="d-none">
                      <img
                        src={searchIconGrey}
                        alt=""
                        className="img-fluid search-icon"
                      />
                    </div>
                  </span>
                  <input
                    ref={input}
                    id="search"
                    type="search"
                    name="search"
                    onChange={e => {
                      onChange(e);
                    }}
                    aria-describedby="searchBox"
                    value={searchText}
                  />
                  <span className="input-group-text text-one" id="searchBox">
                    <div className="d-flex">
                      {searchText !== null && searchText !== "" && (
                        <img
                          onClick={clearText}
                          src={closeIcon}
                          alt=""
                          className="img-fluid"
                        />
                      )}
                    </div>
                  </span>
                  <span className="input-group-text text-two" id="searchBox">
                    <div className="d-flex">
                      <img
                        onClick={() => {
                          getUserState();
                        }}
                        src={navigateIcon}
                        alt=""
                        className="img-fluid nav-icon"
                      />
                      <button type="submit" className="remove-button-style">
                        <img
                          // onClick={submitForm}
                          src={searchIcon}
                          alt=""
                          className="img-fluid search-icon"
                        />
                      </button>
                    </div>
                  </span>
                </div>
              </form>
            </div>
          </nav>

          <div className="row city-list dragscroll">
            {states.map((state, index) => (
              <StateItem
                state={state}
                key={index}
                currentState={currentState}
                onClick={changeState}
              />
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HeaderBar;
