import React, { useState, useEffect, useRef, Fragment } from "react";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import HeaderBar from "../Components/HeaderBar";
import Pagination from "../Components/Pagination";
import ShopItem from "../Components/ShopItem";
import Marker from "../Components/Marker";
import MyMarker from "../Components/MyMarker";
import MapStyle from "./MapStyle";
import ContentLoader from "react-content-loader";
import $ from "jquery";
import { getDistance, findNearest } from "geolib";
import axios from "axios";
import GoogleMap from "google-map-react";
import GeoCode from "react-geocode";
import { fitBounds } from "google-map-react/utils";
import interact from "interactjs";
import data from "../data";

// sort constants for sorting
const LOCATION = "location";
let defaultCoords = {};
let shops = [];
let loadTime;

const Home = ({ history, match, status }) => {
  const map = useRef(null);
  const dragger = useRef(null);

  const [viewShops, setViewShops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentLat, setCurrentLat] = useState(null);
  const [currentLong, setCurrentLong] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [pagination, setPagination] = useState({
    totalPages: null,
    pages: [],
    currentPage: 1
  });
  const [searchSummary, setSearchSummary] = useState(null);
  const [currentState, setTheState] = useState({});
  const [searchText, setText] = useState("");
  const [center, setCenter] = useState({ zoom: null, center: null });
  const [current, setCurrent] = useState(null);
  const [states, setStates] = useState([]);
  const [isFiltered, setIsfiltered] = useState(false);
  const [pageStatus, setStatus] = useState(200);

  const { currentPage, pages } = pagination;

  // const bring to front
  const bringToFront = (arr, old_index, new_index, shop) => {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length;
      while (k-- + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    setPagination({ ...pagination, [pages[currentPage - 1][0]]: arr });
    // setViewShops([...arr]);
    return setCurrent(shop);
  };

  // paginate function
  const paginate = (array, pageSize, pageNumber) => {
    --pageNumber;
    return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
  };

  // get components by state
  const getStates = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://mjnowapp4.ngrok.io/availableLocales"
      );
      setStates([...Object.keys(res.data).map(i => res.data[i])]);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  // find total deals and disps
  const summarizeSearch = () => {
    let deals = [];
    let dispensaries;
    dispensaries = viewShops.length;
    viewShops.map(shop => {
      return deals.push(shop.deals.length);
    });
    deals = deals.reduce((a, b) => a + b, 0);
    return {
      deals,
      dispensaries
    };
  };

  // get users state by state
  const getUserState = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        GeoCode.setApiKey("AIzaSyDoxcSKTuK6UM_34s2DH6HAzdgSwgwwhvs");
        GeoCode.fromLatLng(latitude, longitude).then(
          response => {
            response.results[0].address_components.map(address => {
              switch (address.types[0]) {
                case "administrative_area_level_1":
                  console.log(address.short_name);
                  const state = address.short_name;
                  const matchingState = states.filter(iState => {
                    return iState.stateAbbr === state;
                  });
                  if (matchingState[0]) {
                    return setTheState(matchingState[0]);
                  }
                  return setLoading(false);
                default:
                  return null;
              }
            });
          },
          error => {
            setLoading(false);
            console.error(error);
          }
        );
        setCurrentLat(latitude);
        setCurrentLong(longitude);
        // setCurrentLat(33.4475375);
        // setCurrentLong(-112.0974347);
      },
      err => {
        setLoading(false);
        console.log(err);
      },
      { timeout: 20000 }
    );
  };

  // fetch data per state
  const getDealsByState = state => {
    // get current postion and calculate distance after loading data;
    const getShops = async () => {
      const startTime = new Date();
      setLoading(true);
      // shops = data.result;
      // setLoading(false);
      // setViewShops(shops);
      try {
        const response = await axios.get(
          `https://mjnowapp4.ngrok.io/getDeals?state=${state}`
        );
        shops = response.data.result;
        const endTime = new Date();
        loadTime = endTime - startTime;
        loadTime /= 1000;
        loadTime = Math.round(loadTime * 100) / 100;
        setLoading(false);
        if (currentLat && currentLong && !searchText) {
          return sortByLocation(response.data.result);
        }
        if (searchText) {
          return filterByKeyword(searchText);
        }
        return setViewShops(response.data.result);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    };
    if (currentState.stateAbbr) {
      getShops();
    }
  };

  useEffect(() => {
    getStates();
    const position = { y: 0 };
    let height = null;
    let drawer = "half";
    let offHeight = 0;
    interact(".draggable").draggable({
      modifiers: [
        interact.modifiers.snap({
          targets: [
            { x: 200, y: 200 },
            { x: 250, y: 350 }
          ],
          range: 10,
          relativePoints: [{ x: 0, y: 0 }]
        })
      ],
      inertia: true,
      listeners: {
        start(event) {
          height = dragger.current.getBoundingClientRect().height;
          dragger.current.style.transition = "height 0s";
          if (window.innerWidth > 767.97) {
            offHeight = 36;
          }
        },
        move(event) {
          position.y += event.dy;
          dragger.current.style.height = `${height - position.y}px`;
        }
      },
      onend(e) {
        if (e.velocityY < 0 && drawer === "half") {
          dragger.current.style.height = `calc(100% - 141px + ${offHeight}px)`;
          dragger.current.style.transition = "height 0.3s";
          drawer = "full";
          return (position.y = 0);
        }
        if (e.velocityY < 0 && drawer === "min") {
          dragger.current.style.height = `40%`;
          dragger.current.style.transition = "height 0.3s";
          // setTimeout(() => {
          //   const mapHeight =
          //     dragger.current.getBoundingClientRect().height + 141;
          //   map.current.style.height = `calc(100% - ${mapHeight}px + ${offHeight}px )`;
          // }, 500);

          drawer = "half";
          return (position.y = 0);
        }
        if (e.velocityY > 0 && drawer === "full") {
          dragger.current.style.height = `40%`;
          dragger.current.style.transition = "height 0.3s";
          drawer = "half";
          return (position.y = 0);
        }
        if (e.velocityY < 0 && drawer === "full") {
          dragger.current.style.height = `calc(100% - 141px - ${offHeight}px)`;
          dragger.current.style.transition = "height 0.3s";
          drawer = "full";
          return (position.y = 0);
        }
        if (e.velocityY > 0 && drawer === "half") {
          dragger.current.style.height = `5%`;
          dragger.current.style.transition = "height 0.3s";
          // setTimeout(() => {
          //   const mapHeight =
          //     dragger.current.getBoundingClientRect().height + 141;
          //   map.current.style.height = `calc(100% - ${mapHeight}px + ${offHeight}px)`;
          // }, 300);
          drawer = "min";
          return (position.y = 0);
        }
        if (e.velocityY > 0 && drawer === "min") {
          dragger.current.style.height = `5%`;
          dragger.current.style.transition = "height 0.3s";
        }
      }
    });

    const tag = document.createElement("script");
    tag.async = true;
    tag.src = "https://www.googletagmanager.com/gtag/js?id=UA-154778568-1";
    document.body.appendChild(tag);
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
  
  gtag("config", "UA-154778568-1", {
      page_path: history.location.pathname + history.location.search
    });
    history.listen((location, action) =>
      gtag("config", "UA-154778568-1", {
        page_path: location.pathname + location.search
      })
    );
  }, []);

  useEffect(() => {
    if (currentState.stateAbbr) {
      setCurrent(viewShops[0]);

      const totalPages = Math.ceil(viewShops.length / 10);
      let pages = [];
      let i;
      for (i = 1; i <= totalPages; i++) {
        pages.push(paginate(viewShops, 10, i));
      }
      setPagination({ ...pagination, totalPages: totalPages, pages: pages });
      if (viewShops.length !== 0 && searchText !== "") {
        const searchSummary = summarizeSearch();
        setSearchSummary(searchSummary);
      }
      if (viewShops.length === 0) {
        setSearchSummary(null);
      }
    }
  }, [viewShops]);

  useEffect(() => {
    $(".overflow-wrapper").scrollTop(0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    if (pages[currentPage - 1] && pages[currentPage - 1].length === 1) {
      defaultCoords.lat = pages[currentPage - 1][0].latitude;
      defaultCoords.lng = pages[currentPage - 1][0].longitude;
      const bounds = {
        nw: {
          lat: pages[currentPage - 1][0].latitude + 0.002,
          lng: pages[currentPage - 1][0].longitude - 0.002
        },
        se: {
          lat: pages[currentPage - 1][0].latitude - 0.002,
          lng: pages[currentPage - 1][0].longitude + 0.002
        }
      };
      if (map.current) {
        const size = {
          width: map.current.getBoundingClientRect().width,
          height: map.current.getBoundingClientRect().height
        };
        const { center, zoom } = fitBounds(bounds, size);

        return setCenter({
          center,
          zoom: zoom
        });
      }
    }
    if (pages[currentPage - 1] && pages[currentPage - 1].length !== 0) {
      defaultCoords.lat = pages[currentPage - 1][0].latitude;
      defaultCoords.lng = pages[currentPage - 1][0].longitude;
      const bounds = {
        nw: {
          lat: Math.max.apply(
            Math,
            pages[currentPage - 1].map(item => {
              return item.latitude;
            })
          ),
          lng: Math.min.apply(
            Math,
            pages[currentPage - 1].map(item => {
              return item.longitude;
            })
          )
        },
        se: {
          lat: Math.min.apply(
            Math,
            pages[currentPage - 1].map(item => {
              return item.latitude;
            })
          ),
          lng: Math.max.apply(
            Math,
            pages[currentPage - 1].map(item => {
              return item.longitude;
            })
          )
        }
      };
      if (map.current) {
        const size = {
          width: map.current.getBoundingClientRect().width,
          height: map.current.getBoundingClientRect().height
        };

        const { center, zoom } = fitBounds(bounds, size);
        if (window.innerWidth < 991.99) {
          center.lat =
            Math.min.apply(
              Math,
              pages[currentPage - 1].map(item => {
                return item.latitude;
              })
            ) - 0.2;
          setCenter({
            center,
            zoom: zoom - 1
          });
        } else {
          center.lng = Math.min.apply(
            Math,
            pages[currentPage - 1].map(item => {
              return item.longitude;
            })
          );
          setCenter({
            center,
            zoom: zoom
          });
        }
      }
    }
  }, [pagination]);

  useEffect(() => {
    setLoading(true);
    if (match.params.state) {
      const matchingState = states.filter(iState => {
        return (
          iState.stateName.toLowerCase().replace(/ /g, "-") ===
          match.params.state
        );
      });

      if (matchingState[0]) {
        if (history.location.search) {
          const { s } = queryString.parse(history.location.search);
          if (s) {
            setText(s);
            history.push({
              pathname: `/united-states/${matchingState[0].stateName
                .toLowerCase()
                .replace(/ /g, "-")}/deals/search`,
              search: `s=${s}`
            });
            setTheState(matchingState[0]);
            return setIsfiltered(true);
          }
          if (!s) {
            setTimeout(() => setLoading(false), 2000);
            return setStatus(404);
          }
        }
        return setTheState(matchingState[0]);
      }
      if (!matchingState[0]) {
        setTimeout(() => setLoading(false), 2000);
        return setStatus(404);
      }
    }
    if (history.location.pathname === "/") {
      getUserState();
    }
  }, [states]);

  useEffect(() => {
    setPagination({ ...pagination, currentPage: 1 });
  }, [pages]);

  const filterByKeyword = search => {
    // filter by search keyword
    let filteredShops = [];
    if (!search) {
      filteredShops = shops;
    } else {
      shops.map(shop => {
        if (shop.name.toUpperCase().includes(search.toUpperCase())) {
          return filteredShops.push(shop);
        }
        const filteredDeals = shop.deals.filter(
          deal =>
            deal.detail.toUpperCase().includes(search.toUpperCase()) ||
            deal.title.toUpperCase().includes(search.toUpperCase())
        );
        if (filteredDeals.length > 0)
          filteredShops.push({ ...shop, deals: filteredDeals, search });
      });
    }

    // keep previous sort before filtering
    if (!sortBy) setViewShops(filteredShops);
    if (sortBy === LOCATION) sortByLocation(filteredShops);
  };

  const sortByLocation = items => {
    if (currentLat && currentLong) {
      const updatedItems = items.map(item => {
        const distance =
          (getDistance(
            { latitude: currentLat, longitude: currentLong },
            { latitude: item.latitude, longitude: item.longitude }
          ) *
            0.621371) /
          1000;
        item.distance = Math.round(distance * 10) / 10;
        return item;
      });
      updatedItems.sort((a, b) =>
        a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0
      );
      setViewShops([...updatedItems]);
      setSortBy(LOCATION);
    }
  };

  return (
    <Fragment>
      {currentState.stateName ? (
        <Helmet defaultTitle="JanesDeal: Search Marijuana Deals">
          <title>{`JanesDeal: Search ${currentState.stateName} Marijuana Deals`}</title>
          <meta
            name="description"
            content={`Search ${currentState.stateName} marijuana deals, including keywords, products, brands and dispensaries. JanesDeal has many special features to help you find exactly what you're looking for.`}
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>{`JanesDeal: Search Marijuana Deals`}</title>
          <meta
            name="description"
            content={`Search the world's marijuana deals, including keywords, products, brands and dispensaries. JanesDeal has many special features to help you find exactly what you're looking for.`}
          />
        </Helmet>
      )}
      <div className="home-container">
        <HeaderBar
          currentLat={currentLat}
          currentLong={currentLong}
          filterByKeyword={filterByKeyword}
          sortByLocation={sortByLocation}
          viewShops={viewShops}
          getDealsByState={getDealsByState}
          getUserState={getUserState}
          states={states}
          currentState={currentState}
          setTheState={setTheState}
          searchText={searchText}
          setSearchSummary={setSearchSummary}
          setText={setText}
          setIsfiltered={setIsfiltered}
          pagination={pagination}
          setPagination={setPagination}
          history={history}
          match={match}
          setStatus={setStatus}
        />
        {status === 404 || (pageStatus === 404 && !loading) ? (
          <Fragment>
            <div className="mobile-fluid content-wrapper">
              <h1 className="pt-5 text-center text-danger">404 Not Found</h1>
              <h5 className="m-auto text-center">
                The page you are looking for does not exist
              </h5>
            </div>
          </Fragment>
        ) : (
          <div className="mobile-fluid content-wrapper">
            {loading ? (
              <div className="loading">
                <ContentLoader>
                  <rect x="0" y="10" rx="4" ry="4" width="100%" height="40" />
                  <rect x="0" y="60" rx="3" ry="3" width="100%" height="20" />
                  <rect x="0" y="90" rx="3" ry="3" width="100%" height="20" />
                  <rect x="0" y="120" rx="3" ry="3" width="100%" height="20" />
                </ContentLoader>
              </div>
            ) : (
              <Fragment>
                <div className="col-lg-6 d-lg-flex p-0">
                  <div className="maps-wrapper" ref={map}>
                    <GoogleMap
                      bootstrapURLKeys={{
                        key: "AIzaSyDoxcSKTuK6UM_34s2DH6HAzdgSwgwwhvs"
                      }}
                      defaultCenter={defaultCoords}
                      center={center.center}
                      zoom={center.zoom - 1}
                      defaultZoom={16}
                      options={{
                        styles: MapStyle
                      }}
                    >
                      {currentLat &&
                        currentLong &&
                        pages[0] &&
                        pages[currentPage - 1].length !== 0 && (
                          <MyMarker
                            lat={currentLat}
                            lng={currentLong}
                          ></MyMarker>
                        )}
                      {pages[0] &&
                        pages[currentPage - 1].length !== 0 &&
                        pages[currentPage - 1].map((shop, index) => {
                          return (
                            <Marker
                              index={index}
                              key={index}
                              lat={shop.latitude}
                              lng={shop.longitude}
                              shop={shop}
                              current={current}
                              viewShops={viewShops}
                              setViewShops={setViewShops}
                              bringToFront={bringToFront}
                              pages={pages}
                              currentPage={currentPage}
                            />
                          );
                        })}
                    </GoogleMap>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-6 col-12 results-wrapper drawer"
                    ref={dragger}
                  >
                    <p className="text-center draggable d-lg-none mb-0 pb-3">
                      <i className="fas fa-grip-lines"></i>
                    </p>
                    <div className="overflow-wrapper">
                      {!loading && searchSummary && searchText !== "" && (
                        <p className="search-summary">
                          {searchSummary.deals} deals found at{" "}
                          {searchSummary.dispensaries} dispensaries
                        </p>
                      )}

                      {!loading &&
                        !currentLat &&
                        !currentLong &&
                        !pages[0] &&
                        !currentState.stateAbbr &&
                        !isFiltered && (
                          <h5 className="font-weight-light">
                            Location not enabled, please select your state above
                          </h5>
                        )}
                      {!loading && !pages[0] && isFiltered && (
                        <h5 className="font-weight-light">
                          No deals found with those keywords
                        </h5>
                      )}
                      {!loading &&
                        currentState.deals &&
                        searchText === "" &&
                        !searchSummary && (
                          <p className="search-summary">
                            {currentState.deals} deals found at{" "}
                            {currentState.locations} dispensaries ({loadTime}{" "}
                            seconds)
                          </p>
                        )}
                      <ul className="shopsList">
                        {pages[currentPage - 1] &&
                          pages[currentPage - 1].map((shop, key) => (
                            <ShopItem
                              key={key}
                              item={shop}
                              setCurrent={setCurrent}
                            />
                          ))}
                      </ul>
                      <Pagination
                        pagination={pagination}
                        setPagination={setPagination}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Home;
